<template>
	<div id="newsletter">
		<h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
		<v-spacer class="my-3"></v-spacer>
		<v-container fluid>
			<v-row>
				<!-- Izquierdo -->
				<v-col cols="12" md="6" class="blue-grey lighten-5">
					<v-row dense>
						<v-col md="auto">
							<v-select
								:items="fuentes"
								v-model="stFont"
								style="width:150px"
								label="Font">
							</v-select>
						</v-col>
						<v-col md="auto">
							<v-select 
								:items="brands" 
								v-model="marca"
								style="width:200px"
								label="Brands">
							</v-select>
						</v-col>
						<v-col md="auto">
							<v-text-field
								v-model="buscar"
								label="Search"
								:disabled="!marca"
								v-on:keyup.enter="fnSearch">
							</v-text-field>
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-data-table
						:items="listado"
						:headers="cabecera"
						:loading="stLoading"
						:items-per-page="limit"
						:expanded.sync="abiertos"
						disable-sort
						show-expand
						single-expand
						hide-default-footer>
						<template v-slot:[`item.preview`]="{ item }">
							<v-img :src="item.image" ref="minimg" aspect-ratio="0.8" style="max-width: 150px; border: thin solid #37474F;"></v-img>
						</template>
						<template v-slot:[`item.info`]="{ item }">
							<h3 class="text--h5 text-titulo">{{ item.title }} <v-chip color="blue" dark small>{{ item.type }}</v-chip></h3>
							<v-row>
								<v-col cols="12" md="4">
									<v-text-field
										readonly
										prefix="$"
										color="error"
										label="Base"
										v-model="item.compAt"></v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field
										prefix="$"
										color="success"
										label="Final"
										v-model="item.price"
										v-on:keyup.enter="fnUpdPrice(item.id, item.price)"></v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field
										readonly
										suffix="%"
										label="Discount"
										:value="fnCalculate(item.compAt, item.price)"></v-text-field>
								</v-col>
							</v-row>
							<v-chip-group column>
								<v-chip 
									class="mx-1" 
									color="blue-grey darken-3" 
									small 
									outlined
									label
									v-for="stock in item.stock" 
									:key="stock.title">{{ stock.title }}: {{ stock.qty }}</v-chip>
							</v-chip-group>
							<v-chip-group column>
								<v-chip 
									class="mx-1" 
									color="blue-grey darken-3" 
									small 
									dark
									v-for="chip in item.tags" 
									:key="chip">{{ chip }}</v-chip>
							</v-chip-group>
							<div class="text-uppercase">{{ fnTimeZone(item.date) }}</div>
						</template>

						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length" style="padding:0;">
								<v-card class="pa-5 blue-grey lighten-2" flat>
									<v-row>
										<v-col cols="12" md="auto">
											<v-card class="mx-auto" max-width="350" style="min-width: 350px">
												<vue-cropper
													ref="cropper"
													:src="item.image"
													:aspect-ratio="333/333">
												</vue-cropper>
												<v-spaced></v-spaced>
												<div class="text-center">
													<v-btn
														v-for="img in item.imgttl"
														:key="img"
														text
														@click="fnChgImg(item.id, img)"
														>
															{{ img }}
														</v-btn>
												</div>
											</v-card>
										</v-col>
										<v-col cols="12" md="auto">
											<v-row>
												<v-col>
													<v-btn
														@click="fnCropme">
														<v-icon left>mdi-crop</v-icon>Crop
													</v-btn>
												</v-col>
												<v-col>
													<v-btn
														@click="fnCopy(item.handle)">
														<v-icon left>mdi-debug-step-over</v-icon>Append
													</v-btn>
												</v-col>
												
											</v-row>
											<v-row no-gutters>
												<v-col>
													<v-switch
														v-model="newflag"
														inset
														color="orange lighten-5"
														label="New"></v-switch>
												</v-col>
												<v-col>
													<v-switch
														v-model="priceflag"
														inset
														color="orange lighten-5"
														label="Hide Price"></v-switch>
												</v-col>
											</v-row>
											<v-row no-gutters>
												<v-col>
													<div class="preview" ref="preview" :style="{ 'background-image': 'url('+canvimg+')' }">
														<div v-if="newflag" class="newribbon" :style="{ 'background-image': 'url('+require('../assets/new2.png')+')' }"></div>
														<div class="precios" :style="{ 'font-family': stFont }"><div class="slash"></div>${{ item.compAt }} / <div style="color: #00E676">${{ item.price }}</div></div>
													</div>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
									
								</v-card>
							</td>
						</template>

					</v-data-table>
				</v-col>

				<v-spacer></v-spacer>

				<!-- Derecho -->
				<v-col cols="12" md="6" class="blue-grey lighten-5 px-2">
					<v-row dense>
						<v-col md="auto">
							<v-dialog v-model="wnClear" width="500">
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="red darken-2" large dark @click="wnClear = true" v-bind="attrs" v-on="on">
										<v-icon left>mdi-close</v-icon>Clear
									</v-btn>
								</template>
								<v-card>
									<v-card-title><v-icon left>mdi-alert</v-icon>Warning</v-card-title>
									<v-card-text>
										Do you really want to delete all the generated images?
									</v-card-text>
									<v-divider></v-divider>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="wnClear = false">Cancel</v-btn>
										<v-btn color="primary" text @click="fnClear">Ok</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-col>
						<v-col md="auto">
							<v-btn color="green darken-2" class="mx-1" large dark @click="fnDownload"><v-icon left>mdi-download</v-icon>Download</v-btn>
						</v-col>
					</v-row>
					<br />
					<v-divider></v-divider>
					<v-card flat class="pa-2 blue-grey lighten-3">
						<draggable group="newsletter">
							<transition-group id="pizarra"></transition-group>
						</draggable>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<!-- Context menu -->

	</div>
</template>

<script>
import axios from "axios"
import { DateTime } from "luxon"
import { saveAs } from 'file-saver'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import draggable from 'vuedraggable'
import JsZip from 'jszip'
import * as htmlToImage from 'html-to-image'

export default {
	name: 'senewletter',
	metaInfo: {
		title: 'SE Newsltter'
	},
	components: {
		draggable,
		VueCropper
	},
	data() {
		return {
			fuentes: [ 'Dosis', 'Advent Pro', 'Pompiere', 'Oswald', 'Comfortaa' ],
			brands: [],
			cabecera: [
				{ text: 'Image', value: 'preview', width: '20%' },
				{ text: 'Info' , value: 'info'}
			],
			listado: [],
			abiertos: [],
			stLoading: false,
			limit: 10,
			stFont: 'Comfortaa',
			marca: null,
			buscar: null,
			wnClear: false,
			cursor: null,
			stPrev: true,
			stNext: true,
			canvimg: null,
			dialogo: false,
			newflag: false,
			priceflag: false,
			bloques: []
		}
	},
	mounted() {
		axios.post('/se-brands')
			.then(response => this.brands = response.data)
		this.$store.state.titulofix = 'Newsletter Maker Steven'
	},
	methods: {
		fnLoad() {
			this.stLoading = true
			axios.post('/se-newsbrand', { brand: this.marca, limit: this.limit, cursor: this.cursor, dir: this.dir, word: this.buscar })
				.catch( () => {
					this.$store.state.mensaje = 'Error: server side error. Wait a moment and try again.'
					this.$store.state.estado = 'error'
					this.$store.state.snackme = true
					this.$errorsnd()
					this.stLoading = false
				} )
				.then(response => {
					this.listado = response.data.list
					this.stPrev = !response.data.prev
					this.stNext = !response.data.next
					this.stLoading = false
				})
		},
		fnSearch() {
			this.abiertos = []
			this.cursor = null
			this.canvimg = null
			this.fnLoad()
		},
		async fnCopy(handle) {
			const ele = this.$refs.preview
			await htmlToImage.toJpeg(ele, { quality: 0.90 })
				.then( dataurl => {
					let img = new Image()
					img.src = dataurl
					img.setAttribute('data-handle', handle)
					img.addEventListener('contextmenu', function(e) {
						e.preventDefault()
						this.remove()
						console.log(self)
					}) 
					document.getElementById('pizarra').appendChild(img)
					//this.$refs.pizarra.appendChild(img)
				} )
			this.abiertos = []
			this.canvimg = null
		},
		fnChange(dir) {
			if(dir == 'next') {
				const len = Object.keys(this.listado).length;
				this.cursor = this.listado[len-1].cursor;
			}else{
				this.cursor = this.listado[0].cursor;
			}
			this.dir = dir;
			this.abiertos = []
			this.listado = []
			this.fnLoad();
		},
		fnChgImg(id, idx) {
			axios.post('/se-newsimage', { id: id, index: idx })
				.then( response => {
					this.$refs.cropper.replace(response.data.image)
				})
		},
		fnCropme() {
			this.canvimg = this.$refs.cropper.getCroppedCanvas().toDataURL()
		},
		fnDownload() {
			const fecha = DateTime.fromISO(DateTime.local(), { zone: "America/New_York" }).toSQLDate()
			const brand = this.marca.toLowerCase().substr(0, 3)
			var zip = new JsZip()
			var temp = "<tr>\n\t<td style='margin: 0px; padding: 0px; border-collapse: collapse; border-spacing: 0px; text-align: center; width: 100%' colspan='2'>\n\t\t<a href='https://www.steveneven.com/collections/yyy?zzz'>\n\t\t\t<img src='https://stevenevens.com/pic/xxx' border='0' style='display: block; width: 100%' />\n\t\t</a>\n\t</td>\n</tr>\n"
			var oImages = document.getElementById('pizarra').children
			oImages.forEach((obj, inx) => {
				let nombre = brand + (inx+1) + "_" + fecha + ".jpg"
				let datos = obj.src.split('base64,')
				zip.folder(this.marca.toLowerCase() + '-' + fecha).file(nombre, datos[1], {base64: true})
				if(inx % 2 == 0) {
					temp += "<tr>\n"
				}
				temp += "\t<td style='margin: 0px; padding: 0px; border-collapse: collapse; border-spacing: 0px; text-align: center; width: 50%' align='center'><a href='https://www.steveneven.com/collections/"+this.marca+"/products/"+obj.getAttribute('data-handle')+"'><img src='https://stevenevens.com/pic/"+nombre+"' border='0' style='display: block; width: 100%' /></a>\n\t</td>\n"
				if(inx % 2 != 0) {
					temp += "</tr>\n"
				}
			})
			zip.folder(this.marca.toLowerCase() + '-' + fecha).file('tmpl.htm', temp);
			zip.generateAsync({type:"blob"}).then(function(content) {
				saveAs(content, "example.zip");
			})
		},
		fnClear() {
			document.getElementById('pizarra').innerHTML = ''
			this.wnClear = false
		},
		fnUpdPrice(id, price) {
			let ids = [id]
			axios.post('/se-massupdate', {ids: ids, price: price})
				.then(() => {
					this.$successsnd()

				})
		},
		fnCalculate(compAt, price) {
			return Math.round((compAt - price) / compAt * 100);
		},
		fnTimeZone(val) {
			const fecha = DateTime.fromISO(val, { zone: "America/New_York" })
			return fecha.toRelativeCalendar()
		},
	}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Dosis&family=Comfortaa&family=Advent+Pro&family=Pompiere&display=swap');
.preview {
	position: relative;
	width: 333px !important;
	height: 333px !important;
	background-size: cover;
	background-color: darkgrey;
	box-sizing: border-box;
	border: 2px solid rgba(0, 0, 0, 0.9);
	overflow: hidden;
}
.precios {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 45px;
	text-align: center;
	font-size: 35px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	line-height: 100%;
}
.precios > .slash {
	height: 3px;
	background-color: rgba(237,237,237,0.95);
	position: absolute;
	top: 42%;
	left: 20%;
	width: 25%;
	transform: rotate(-18deg);
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
}
.newribbon {
	position: absolute;
	top: 2;
	left: 2;
	width: 90px;
	height: 90px;
	background-size: cover;
}
.text--h5 {
	font-size: 1.5rem;
}
</style>